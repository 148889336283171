<template>
    <div :style="{pointerEvents:readonlyFlag?'none':'auto'}">
        <el-upload
                class="avatar-uploader"
                :style="{width,height}"
                action=" "
                :http-request="uploadHandle"
                :show-file-list="false"
        >
            <template v-if="url">
                <template v-if="type==='pdf'">
                  <img src="../../assets/public/pdf.png" style="width: 67px;height: 67px" alt="">
                </template>
                <template v-else>
                  <img :src="url" class="showUrl" alt="">
                </template>
            </template>
            <template v-else>
                <div class="cmpContent">
                    <img src="../../assets/public/add2.png" alt="">
                    <div>{{name||'点击上传'}}</div>
                </div>
                <div class="tip">
                    <slot>
                    </slot>
                </div>
            </template>
        </el-upload>
    </div>
</template>

<script>
    import {uploadApi} from  '@/api/public'
    export default {
        props:['name','width','height','readonlyFlag','type'],
        name: "upload",
        data(){
            return{
                url:'',
            }
        },
        methods:{
            //上传
            uploadHandle({file}){
                const isType = file.type === "image/png" ;
                const isType2 = file.type === "image/jpeg" ;
                const isLt2M = file.size / 1024 / 1024 < 2;
                if(this.type==='pdf'){
                  const maxSize = file.size / 1024 / 1024 < 5
                    if(file.type!=="application/pdf"){
                        return  this.$message.error('仅支持pdf格式!');
                    }
                    if(!maxSize){
                      return  this.$message.error('文件大小不得超过5M!');
                    }
                }else{
                    if (!(isType||isType2)) {
                        return  this.$message.error('仅支持png、jpg格式!');
                    }
                    if(!isLt2M){
                        return  this.$message.error('文件大小不得超过2M!');
                    }
                }
                this.$ELoading()
                this.importReq= new FormData()
                this.importReq.append('file',file)
                uploadApi(this.importReq).then(res=>{
                    if(res.code){
                        this.$EClose()
                        if(res.code==1000){
                            this.url=res.data
                        }else{
                            this.$message.error('上传失败')
                        }
                    }
                })
            },
        }
    }
</script>

<style scoped>
    .avatar-uploader{
        width: 104px;
        height: 104px;
        border-radius: 4px;
        /*background: rgba(32, 32, 32, 0.03);*/
        border: 1px solid rgba(32, 32, 32, 0.15);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .cmpContent{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .cmpContent>img{
        width: 32px;
        height: 32px;
    }
    .cmpContent>div{
        font-size: 12px;
        font-weight: 400;
        line-height: 22px;
        color: rgba(32, 32, 32, 0.65);
        margin-top: 10px;
    }
    .showUrl{
        width: 100%;
        height: 100%;
        border-radius: 4px;
    }
    ::v-deep  .el-upload{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .tip{
        display: inline-block;
        color: rgba(166, 166, 166, 1);
        font-size: 10px;
        font-weight: 400;
        position: absolute;
        bottom: -30px;
        border: transparent;
    }
</style>
